jQuery(function($){
	'use strict';

	var $articles = $('[data-categories]');
	function filterByHashBang() {
		var locationHref = window.location.hash;
		var hashbang = window.location.hash.replace(/^#!/, '');
		$articles.css('display', '');
		if (hashbang) {
			$articles.not('[data-categories*="' + hashbang + '"]').hide();
		}
	}

	filterByHashBang();
	$(window).on( 'hashchange', filterByHashBang);

	function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
	}

	var isMobile = function(){
    return ($(window).innerWidth < 600);
  };

  $('.search-slide form').submit(function(evt){
    var val = $(this).find('.search').val();
    if (val.length > 0) {
      if (typeof dataLayer === "object") {
        dataLayer.push({
          "event" : "termsPushed",
          "searchSubmit" : true,
          "searchTerms" : val
        });
      }
      return true;
    } else {
      evt.preventDefault();
    }
  });

	$(".share").BEShare({
		'class': 'popup-share',
		'targets': ['Facebook', 'LinkedIn', 'Twitter', 'Google+'],
		'prefix': ''
	})

	$(".inline-share").BEShare({
		'type': 'inline',
		'class': 'inline-share',
		'targets': ['LinkedIn', 'Twitter', 'Email', 'Print'],
		'prefix': 'icon-circle-'
	})

	jQuery(".job-form #file").on('change', function () {
    var display = jQuery(this).parent().parent().find("#file-display");
    var val = jQuery(this).val().split("\\").pop();
    display.val(val);
  });

 	if($(".share-graphic").length > 0) {
	 	var $graphic = $(this);

	 	var copy = {
		 	"attract": "<h4>Attract</h4><p>Our services help clients in the attraction of key talent and include assistance with such things as compensation strategy, benchmarking compensation levels, sign-on awards, and employment agreements.</p>",
		 	"retain": "<h4>Retain</h4><p>We help clients retain key employees by providing assistance with such things as benchmarking total compensation, innovative incentive plan design, retention program design, and deferred compensation programs.</p>",
		 	"motivate": "<h4>Motivate</h4><p>We assist with the design and administration of compensation tools that motivate key employees including long and short-term incentive plan design aligned with business objectives, compensation mix, key performance indicators, goal-setting analytics, and special incentive arrangements.</p>",
		 	"align": "<h4>Align</h4><p>Our consultants are experienced in aligning executive pay with shareholders&rsquo; interests including value drivers, strategic incentives, award vehicle selection, stock ownership/holding requirements, director compensation plans, pay and performance modeling, and peer group selection.</p>",
		 	"communicate": "<h4>Communicate</h4><p>Communicating with stakeholders is increasingly important, and we assist with stock plan modeling and design, participant communications, say-on- pay assistance, shareholder outreach, CD&amp;A support, and proxy advisory firm interface.</p>",
		 	"comply": "<h4>Comply</h4><p>Our experts help simplify compliance with such issues as accounting for compensation, tax considerations, SEC disclosure issues, institutional investor policies, stock exchange requirements, and program risk assessment.</p>",
		 	"transact": "<h4>Transact</h4><p>Corporate transactions such as IPOs, mergers, spin offs, acquisitions significantly impact executive pay. Our comprehensive assistance helps with employment agreements, severance benefits, golden parachutes, incentive plan alignment, integration advice, and transition strategies.</p>",
		 	"govern": "<h4>Govern</h4><p>Good corporate governance is essential in today&rsquo;s market. Our ongoing advisory services drive solid governance concepts, process improvement, committee calendars, committee charter reviews, trends and regulatory updates, committee education, and best practices.</p>"
	 	}

		$("svg .cover").hover(
			function(e) {
				var id = $(this).attr("id");
				var position = "right";
				if(id === "govern" || id === "transact" || id === "comply" || id === "communicate") {
					position = "left";
				}
				$("svg .st71."+id).attr("class", "st71 active "+id);
				$(".copy-holder").html(copy[id]);
				$(".copy-holder").removeClass("left");
				$(".copy-holder").removeClass("right");
				$(".copy-holder").addClass(position);
				$(".copy-holder").addClass("active");
			}, function(e) {
				var id = $(this).attr("id");
				$("svg .st71."+id).attr("class", "st71 "+id);
				$(".copy-holder").removeClass("active");
			}
		)
 	}

	$('.anchor-links a[href^="#"]').on('click',function(e) {
    e.preventDefault();
    var target = this.hash;
		var offset = 0;

		if($("body").hasClass("samadmin")) {
			offset = offset + $("#SAMAdminTools").height() + $("header").height();
		} else {
			offset = offset + $("header").height();
		}

    if(this.hash === "#body") {
      target = "body";
    }

    if($(target).length > 0) {
      var top = $(target).offset().top - offset;

      $('html, body').stop().animate({
        'scrollTop': top
      }, 500, 'swing');
    }
  })


  $(".back-top").click(function(e) {
	  $('html, body').stop().animate({
        'scrollTop': 0
      }, 500, 'swing');
  });


/*
  $(".masthead-carousel").each(function() {
	  var slides = $(".slide",$(this));
	  var selection = Math.floor(Math.random() * slides.length);
	  $(slides[selection]).addClass("active");
  })
*/


  $(".view-as").each(function(){
	  $(".view-list",$(this)).click(function(e){
		  e.preventDefault();
		  if(!$(this).hasClass("active")){
			  $(".results").removeClass("grid-view");
			  $(".results").addClass("list-view");
			  $(".results > .grid").removeClass("grid");
			  $(".view-as .view-grid").removeClass("active");
			  $(this).addClass("active");
		  }
	  })

	  $(".view-grid",$(this)).click(function(e){
		  e.preventDefault();
		  if(!$(this).hasClass("active")){
			  $(".results").removeClass("list-view");
			  $(".results").addClass("grid-view");
			  $(".results > div").addClass("grid");
			  $(".view-as .view-list").removeClass("active");
			  $(this).addClass("active");
		  }
	  })
  })

  $(".results.grid-view .result figure").click(function(e) {
	  //console.log(e);
	  if(e.target.nodeName !== "A") {
		  //console.log($("a",$(this)).first())
		  window.location.href = $("a",$(this)).first().attr("href");
	  }
  })

  $("a[href='#lightbox']").each(function() {
	  $(this).click(function(e) {
		  e.preventDefault();
		  var content = $(this).attr("rel");
		  $(".lightboxMask").addClass("active");
		  $(".lightboxMask .content."+content).addClass("active");

		  if(content === "resume") {
			  //var title = $(this).parents(".accordionSlide").find(".jobTitle");
			  var title = $(this).attr("data-title");
			  var location = $(this).attr("data-location");

				$("form.job-form input.job-name").val(title);
				$("form.job-form input.location").val(location);
		  }

		  if(content === "share") {
			  //var title = $(this).parents(".accordionSlide").find(".jobTitle");
			  //var ID = $(this).parents(".accordionSlide").attr("id");
			  var title = $(this).attr("data-title");
			  var ID = $(this).attr("data-id");
				$("form.job-form input.job-name").val(title);
				$("form.share-form input.jobID").val(ID);
		  }
	  })
  })

  $(".lightboxMask .modal .close").click(function(e){
	  $(".lightboxMask").removeClass("active");
	  $(".lightboxMask .content").removeClass('active');
  })

  $(".advisors figure").click(function(e){
	  window.location.href = $("a",$(this)).first().attr("href");
  })

  $(".summary.clickable, .latest-resource.clickable").click(function(e){
	  if(e.target.nodeName !== "A") {
		  var $link = $("a",$(this)).last();
      //console.log($link);
      $link[0].click();
/*
		  if($link.attr("target") === "_blank") {
			  window.open($link.attr("href"),"_blank");
		  } else {
			  window.location.href = $link.attr("href");
		  }
*/
	  }
  })


	$(".masthead-carousel .slides.owl-carousel").owlCarousel({
		responsive:{
			0: {
				items: 1
			}
		},
		slideBy: "page",
    mouseDrag: false,
		nav: true,
		navText: ["<span class='icon-open-left-angle'></span>","<span class='icon-open-right-angle'></span>"],
		addClassActive: true
	})


	$(".content .general.owl-carousel").owlCarousel({
		responsive:{
        0:{
            items: 1
        },
        700:{
					items: 2
        }
    },
    slideBy: "page",
    loop: true,
    mouseDrag: false,
		nav: true,
		navText: ["<span class='icon-open-left-angle'></span>","<span class='icon-open-right-angle'></span>"],
		addClassActive: true
	})

	$(".content .home-pubs.owl-carousel").owlCarousel({
    slideBy: "page",
    loop: true,
    mouseDrag: false,
		nav: true,
		navText: ["<span class='icon-open-left-angle'></span>","<span class='icon-open-right-angle'></span>"],
		addClassActive: true,
		responsive:{
      0:{
          items: 1
      },
      700:{
				items: 2
      },
      1024:{
        items: 4,
        nav: false
      }
    }
	})

	$(".content .advisors.owl-carousel").owlCarousel({
		responsive:{
        0:{
            items: 1
        },
        500:{
					items: 2
        },
        800:{
	        items: 3
        },
        1000: {
	        items: 4
        }
    },
    slideBy: "page",
    loop: true,
    mouseDrag: false,
		nav: true,
		navText: ["<span class='icon-open-left-angle'></span>","<span class='icon-open-right-angle'></span>"],
		addClassActive: true
	})

/*
	$(".tabs").each(function(index, value) {
		$(".tab-buttons .button").each(function(index, value){
			$(value).click(function(e) {
				$(".tab-buttons .button").removeClass("active");
				$(".tabbed-content > div").removeClass("active");
				if(index === 0) {
					$(value).addClass("active");
					$(".tabbed-content > div").addClass("active");
				} else {
					$(value).addClass("active");
					$(".tabbed-content > div").eq(index-1).addClass("active");
				}
			})
		})
	})
*/






	$(".tabs").each(function(index, value) {
		var $tabs = $(this);
		var startValue = $(".tab-buttons #industry", $tabs).val();
		$(".tabbed-content > div",$tabs).removeClass("active");
		$(".tabbed-content div[data-tab='"+startValue+"']", $tabs).addClass("active");
		$(".tabbed-content .selection",$tabs).text(startValue);

		$(".tab-buttons #industry", $(this)).change(function(){
			$(".tabbed-content > div",$tabs).removeClass("active");
			$(".tabbed-content div[data-tab='"+$(this).val()+"']", $tabs).addClass("active");
			$(".tabbed-content .selection",$tabs).text($(this).val());
		})
	});





	if($(".tabs").length > 0) {
	  var $pnav = $("header");
	  var offset = $pnav.offset().top + $pnav.height();
	  var $select = $(".tabs .industry-select")
	  if($select.length > 0) {
	  	var selectPos = $select.offset().top;
	  }
	  var $win = $(window);

		var scrollz = function(){
			var stickingPoint = this.pageYOffset + offset + 100;

			if(!isMobile()) {



			  if ($win.scrollTop() > ($win.height() / 2)) {
			    $('.back-top').addClass('show');
			  } else {
			    $('.back-top').removeClass('show');
			  }


				if(stickingPoint >= selectPos && !$select.hasClass("sticky")) {
					$select.addClass("sticky");
				} else if(stickingPoint < selectPos && $select.hasClass("sticky")) {
					$select.removeClass("sticky");
				}
			}
	  };


	  $(window).on({
	    'scroll' : $.throttle(100,scrollz)
	  });

	} else {
		var $win = $(window);
		var scrollz = function(){

			if(!isMobile()) {
			  if ($win.scrollTop() > ($win.height() / 2)) {
			    $('.back-top').addClass('show');
			  } else {
			    $('.back-top').removeClass('show');
			  }
			}
	  };

	  $(window).on({
	    'scroll' : $.throttle(100,scrollz)
	  });
	}

	$('.page-container > footer').appear({
    'interval':500
  });

  var $win = $(window);

  if ($win.scrollTop() > ($win.height() / 2)) {
    $('.back-top').addClass('show');
  } else {
    $('.back-top').removeClass('show');
  }

	$('.page-container > footer').on({
    'appear':function(){
      $('.back-top').removeClass('sticky');
    },
    'disappear':function(){
      $('.back-top').addClass('sticky');
    }
  });















	$("#main-menu > .main-nav > ul > li.isParent").click(function(e){
		if(($('html').hasClass('touchevents') || $("#main-menu").is("nav:target")) && !$(this).hasClass("mobile-active")) {
			e.preventDefault();

			$(".primary-nav nav:target, html.touchevents .primary-nav nav").find(".main-nav > ul > li.isParent").removeClass("mobile-active");
			$(this).addClass("mobile-active");
/*
			$(".primary-nav nav:target > ul > li > ul").css("max-height","0");
			console.log($(this).find(">ul"));
			$(this).find("ul").css("max-height","20em");
*/
		}
	});

	$(document).on('click.outsidemenu, touchstart.outsidemenu', function(event) {
  	// Detect click/touch outside of the primary nav item and clear the class
  	// so that clicking it again will show the dropdown instead of going to link.
  	var $target = $(event.target);

    if(!$target.closest('#main-menu > .main-nav > ul > li.isParent').length &&
       !$target.is('#main-menu > .main-nav > ul > li.isParent') && !$target.is("#main-menu") &&
       !$target.parents("#main-menu").length) {
      $(".primary-nav nav:target, html.touchevents .primary-nav nav").find(".main-nav > ul > li.isParent").removeClass("mobile-active");
    }
  });




	var checkHeight = function(filteredEntries,itemsByRow) {
		var highestEl;
		var highestVal;

		filteredEntries.each(function(index, value){

			$(value).removeClass("highest");

			if(index%itemsByRow === 0 && index !== 0) {
				highestEl.addClass("highest");
			}
			if(index%itemsByRow === 0) {
				highestVal = 0;
				highestEl = $(value);
			}

			if($(value).height() > highestVal) {
				highestVal = $(value).height();
				highestEl = $(value);
			}

			if(index === filteredEntries.length-1) {
				highestEl.addClass("highest");
			}


		})

		return filteredEntries;
	};

	var refreshEntries = function(entries, filteredEntries, itemsPerPage, init) {

		var numOfEntries = filteredEntries.length;

		entries.each(function(){
			//$(this).css("display","none");
			$(this).removeClass("show");
		})

		for(var i = 0; i<itemsPerPage; i++) {
			//$(filteredEntries[i]).css("display", "inline-block");
			$(filteredEntries[i]).addClass("show")
			.toggleClass('odd', (i + 1) % 2 === 1)
			.toggleClass('even', (i + 1) % 2 === 0);
		}

		$('.paginator').remove();
		$('#numItems').remove();

		if(numOfEntries > itemsPerPage) {
			var $itemSelector = $("<div id='numItems'>Display Per Page <div class='styled-select'><select><option value='12'>Items/Page</opttion></select></div></div>");

			for(var i = 12; i <= numOfEntries && i <= 100; i = i + 12) {
				var selected = "";

				if(i === parseInt(itemsPerPage) && !init) {
					selected = "selected";
				}
				$("select",$itemSelector).append("<option value='"+i+"' "+selected+" >"+i+"</option>");
			}

			$('.results + .page-controls').append("<ul class='paginator'></ul>");
			$('.results + .page-controls').append($itemSelector);


			$("#numItems select").change(function(e,value){
				//console.log($(this).val());
				refreshEntries(entries, filteredEntries, $(this).val());
			})

			$(".paginator").twbsPagination({
				totalPages: Math.ceil(numOfEntries/itemsPerPage),
		    visiblePages: 5,
		    prev: "<",
		    next: ">",
		    first: false,
		    last: false,
		    onPageClick: function (event, page) {
		      filteredEntries.each(function(){
						//$(this).css("display","none");
						$(this).removeClass("show");
					})

		      for(var i = (page-1)*itemsPerPage; i < page*itemsPerPage; i++) {
			    	//$(filteredEntries[i]).css("display","inline-block");
			    	$(filteredEntries[i]).addClass("show")
						.toggleClass('odd', (i + 1) % 2 === 1)
						.toggleClass('even', (i + 1) % 2 === 0);
		      }
		    }
			});
		}

	}

	var filterEntries = function(filters, entries) {
		var filteredEntries = entries;

		if(!$.isEmptyObject(filters)) {
			//filteredEntries = entries;

			$.each(filters, function(key,value){
				filteredEntries = filteredEntries.filter(function(){
					if(key === "data-topic") {
						return $(this).find(".title").html().toLowerCase().indexOf(value.toLowerCase()) > -1;
					} else if(key === "data-letter") {
						var splitName = $(this).attr("data-name").split(" ");
						var lastName = splitName[splitName.length - 1];
						return lastName[0] === value;
					} else if(key === "data-position"){
						if($(this).attr(key).indexOf(value) > -1) {
							return true;
						} else {
							if($(this).attr("data-alt-titles")) {
								return $(this).attr("data-alt-titles").indexOf(value) > -1;
							}
						}
						return false;
					} else {
						return $(this).attr(key).indexOf(value) > -1;
					}
				})
			})
			//return filteredEntries;
			//numOfEntries = filteredEntries.length;
		}
		return filteredEntries;
	}

	function updateResourceTypeByHash() {
		var hash = window.location.hash.replace('#', '');
		if (hash) {
  		$('form.search-resources #type option').each(function(){
        if (this.value ===  hash) {
          $('form.search-resources #type').val(hash);
          return false;
        }
      });

      $("form.search-resources").submit();
		}
	}


	$("form.search-resources").each(function(){
		var $form = $(this);
		var filteredEntries;
		var numOfEntries;
		var itemsPerPage = 12;
		var entries = $(".results .result");

		var updateTitles = function() {
			var filters = {};

			if($("form.search-resources #type").val() !== "0") {
				filters["data-type"] = $("form.search-resources #type").val();
			}

			if($("form.search-resources #author").val() !== "0") {
				filters["data-authors"] = $("form.search-resources #author").val();
			}

			if($("form.search-resources #year").val() !== "0") {
				filters["data-year"] = $("form.search-resources #year").val();
			}

			var selectedEntries = filterEntries(filters,entries);

			var titles = selectedEntries.find(".title");
			titles = $.map(titles,function(value, index){
				return $(value).html();
			});

			$('#autocomplete').autocomplete({
				lookup: titles
			});
		}

		var years = $.map(entries, function(value, index){
			return parseInt($(value).attr("data-year"));
		});

		years = years.filter(function(value,index) {
			return (index==years.indexOf(value) && value != "");
		});

		function sortNumber(a,b) {
    	return b - a;
		}

		years = years.sort(sortNumber);

		var options = "<option value='0'>Select Year</option>";
		years.forEach(function(value, index){
			options += "<option value='"+value+"'>"+value+"</option>";
		});
		$("#year").html(options);
/*
		var advisors = $.map(entries, function(value, index){
			return $(value).attr("data-authors").split(",");
		});
		var advisors = $.map(advisors, function(value, index){
			return value.trim();
		});

		console.log(advisors);

		advisors = advisors.filter(function(value,index) {
			return (index==advisors.indexOf(value) && value != "");
		});

		advisors = advisors.sort();

		var options = "<option value='0'>Select Author</option>";
		advisors.forEach(function(value, index){
			options += "<option value='"+value+"'>"+value+"</option>";
		});
		$("#author").html(options);
*/


		filteredEntries = filterEntries({},entries);


		//filteredEntries = checkHeight(filteredEntries,4);
		refreshEntries(entries, filteredEntries, itemsPerPage);

		$("form.search-resources").submit(function(e){
			e.preventDefault();
			$(".search-container").addClass("collapse");
			$("#search-return").addClass("show");
			var filterPhrase = [];
			var filters = {};
			//console.log($("form.search-resources #type").val());
			if($("form.search-resources #type").val() !== "0") {
				filters["data-type"] = $("form.search-resources #type").val();
				filterPhrase.push($("form.search-resources #type").val());
			} else {
				filterPhrase.push("All");
			}

			if($("form.search-resources .topic").val() !== "") {
				filters["data-topic"] = $("form.search-resources .topic").val();
				filterPhrase.push("Topic");
			}

			if($("form.search-resources #author").val() !== "0") {
				filters["data-authors"] = $("form.search-resources #author").val();
				filterPhrase.push($("form.search-resources #author").val());
			}

			if($("form.search-resources #year").val() !== "0") {
				filters["data-year"] = $("form.search-resources #year").val();
				filterPhrase.push($("form.search-resources #year").val());
			}

			//$(".filter-type .type").text(filterPhrase.join(" - "));
			$(".filter-type").text("Search Results for: " + filterPhrase.join(" - "));

			filteredEntries = filterEntries(filters,entries);
			refreshEntries(entries, filteredEntries, itemsPerPage);
		});

		$("form.search-resources #type").change(function() {
			updateTitles();
		})

		$("form.search-resources #author").change(function() {
			updateTitles();
		})

		$("form.search-resources #year").change(function() {
			updateTitles();
		})

		updateTitles();

		// Set the view to selected options.
		// $("form.search-resources").submit();

		updateResourceTypeByHash();

		$(window).bind('hashchange', updateResourceTypeByHash);

	});


	$(".search-advisors form").each(function(){
		var $form = $(this);
		var filteredEntries;
		var numOfEntries;
		var itemsPerPage = 12;
		var entries = $(".results .result");


		var updateTitles = function() {
			var filters = {};

			if($(".search-advisors form #letter").val() !== "0") {
				filters["data-letter"] = $(".search-advisors form #letter").val();
			}

			if($(".search-advisors form #position").val() !== "0") {
				filters["data-position"] = $(".search-advisors form #position").val();
			}

			if($(".search-advisors form #location").val() !== "0") {
				filters["data-location"] = $(".search-advisors form #location").val();
			}

			var selectedEntries = filterEntries(filters,entries);

			var names = $.map(selectedEntries,function(value, index){
				return $(value).attr("data-name");
			});

			$('#autocomplete').autocomplete({
				lookup: names
			});
		}






		var positions = $.map(entries, function(value, index){
			return $(value).attr("data-position");
		});

		positions = positions.filter(function(value,index) {
			return (index==positions.indexOf(value) && value != "");
		});

		var options = "<option value='0'>Select a Position</option>";
		positions.forEach(function(value, index){
			options += "<option value='"+value+"'>"+value+"</option>";
		});
		$("#position").html(options);


		var locations = $.map(entries, function(value, index){
			return $(value).attr("data-location");
		});

		locations = locations.filter(function(value,index) {
			return (index==locations.indexOf(value) && value != "");
		});

		var options = "<option value='0'>Select a Location</option>";
		locations.forEach(function(value, index){
			options += "<option value='"+value+"'>"+value+"</option>";
		});
		$("#location").html(options);


		filteredEntries = filterEntries({},entries);
		filteredEntries = checkHeight(filteredEntries,3);
		refreshEntries(entries, filteredEntries, itemsPerPage,true);

		$(".search-advisors form").submit(function(e){
			e.preventDefault();
			var filters = {};

			$('.search-container').addClass("collapse");
			$("#search-return").addClass("show");
			$(".result-header h3").text("Your Results");


			if($(".search-advisors form #letter").val() !== "0") {
				filters["data-letter"] = $(".search-advisors form #letter").val();
			}

			if($(".search-advisors form #name").val() !== "0") {
				filters["data-name"] = $(".search-advisors form .name").val();
			}

			if($(".search-advisors form #position").val() !== "0") {
				filters["data-position"] = $(".search-advisors form #position").val();
			}

			if($(".search-advisors form #location").val() !== "0") {
				filters["data-location"] = $(".search-advisors form #location").val();
			}

			filteredEntries = filterEntries(filters,entries);
			filteredEntries = checkHeight(filteredEntries,3);
			refreshEntries(entries, filteredEntries, itemsPerPage);
		})

		$(".search-advisors form #letter").change(function() {
			updateTitles();
		})

		$(".search-advisors form #position").change(function() {
			updateTitles();
		})

		$(".search-advisors form #location").change(function() {
			updateTitles();
		})

		updateTitles();
	})

	$("#search-return").click(function(e) {
		e.preventDefault();
		$(".search-container").removeClass("collapse");
		$(this).removeClass("show");
	});


	jQuery(".accordion").tabs(".accordion div.accordion-pane",
		{tabs: '.moreAccord', effect: 'slide', initialIndex: null, collapsible: true}
	)

	if(!jQuery("body").hasClass("samadmin")) {
		jQuery(".accordion-pane").hide();
	}

	if(jQuery("body").hasClass("pid1037")) {
		jQuery(".accordionSlide.jobs .lessAccord").on("click", function(){
			jQuery(this).parent().prev().click();
		})
	}

	$(".form-tabs").each(function() {
		var tabs = this;

		$(".tabs li", tabs).each(function(index, value) {
			if(index === 0) {
				$(value).addClass("active");
			}

			$(value).click(function(){

				$(".tabs li", tabs).each(function(){
					$(this).removeClass("active");
				})

				$(".panes .pane", tabs).each(function(){
					$(this).removeClass("active");
				})

				$(value).addClass("active");
				$($(".panes .pane", tabs)[index]).addClass("active");
			})
		})

		$(".panes .pane", tabs).each(function(index, value) {
			if(index === 0) {
				$(value).addClass("active");
			}
		})

	})

	$("ul.categories li").sort(function(a,b) {
		return $(a).text().toUpperCase().localeCompare($(b).text().toUpperCase());
	}).appendTo('ul.categories');

	if (window["tinymce"]) {
		tinymce.on("AddEditor", function (e) {
			var $editor = $("#" + e.editor.id);
			$editor.closest(".owl-stage-outer").css("z-index", 8998);
		});
		tinymce.on("RemoveEditor", function (e) {
			var $editor = $("#" + e.editor.id);
			$editor.closest(".owl-stage-outer").css("z-index", "");
		});
	}

	$(".additional-pubs").each(function(){
		if ($(this).find("h5").next().length > 0) {
			$(this).removeClass("hide");
		}
	});
})
