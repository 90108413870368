jQuery(function($){
  var $window = $(window);
  var $loader = $('.loader').hide();
  var limit = Number($loader.attr('data-limit') || '');
  var selector = $loader.attr('data-for');
  var $items = $(selector);
  var currentIndex = 0;

  // First, hide the ones outside of the viewport.
  // @TODO: make sure the category filter does not interfere with this.
  $items.each(function(index) {
    var $item = $(this);
    var offset = $item.offset();
    var height = $item.height();
    var scrollTop = $window.scrollTop();
    var windowHeight = $window.height();

    if ((offset.top + height) > (scrollTop + windowHeight)) {
      $item.hide();
    } else {
      currentIndex = index;
    }
  });

  // Show the loader if there are more items.
  if (currentIndex < $items.length - 1) {
    $loader.css('display', '');
  }

  // On clicked, shows N more. Hide the loader if no more.
  $loader.on('click', function(event) {
    event.preventDefault();

    $items.filter(':gt(' + currentIndex + '):lt(' + currentIndex + limit + ')').css('display', '');
    currentIndex += limit;

    if (currentIndex >= $items.length - 1) {
      $loader.hide();
    }
  });
});